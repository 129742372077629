import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Caption = makeShortcode("Caption");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Loading patterns are used when information takes an extended amount of time to process and appear on screen. Skeleton states and the loading component are two interactions that communicate to users that data is loading and the screen is not frozen.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Skeleton states</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Progressive loading</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Loading components</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Load more</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "skeleton-states"
    }}>{`Skeleton states`}</h2>
    <p>{`Skeleton states are simplified versions of components used on an initial page load to indicate that the information on the page has not fully loaded yet. They should only appear for one to three seconds, disappearing once the actual content populates the page.`}</p>
    <p>{`Skeleton states improve a web app’s perceived performance as the app appears to load faster than it actually does.`}</p>
    <p>{`Use a `}<inlineCode parentName="p">{`field-01`}</inlineCode>{` rectangular block for text. Skeleton states use motion to convey that the page is not stuck loading but rather that data is still being pulled in.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "52.85714285714286%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAA0UlEQVQoz62QSw6CMBCG66m8hF7EuNO91JWPpcatiVfizQVgTaBAW1r/NmgIcQMyyZdOpzNfJiVkxojjeEF831+5rnsBB0CDIKCoUTzSKIqo53n27Of9HtScMAxPSZIsrTVN05dGFEWh67rWTdNYOOcWUxvm/R7GmBnXWZbtrRCimymUZckAx30slZTSLLSxwjzPH0aIkwM1AamUMvPbX0I9Eivs5ucTDje8d8IKiAnUQggz//3DpxG2bav/CXh2H+EaXIEDjhOg4AyWZO6AlLwB04N5J+1UL5IAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of a text skeleton state in a data table",
        "title": "Example of a text skeleton state in a data table",
        "src": "/static/c2092a2b1e1e583db16f32cac08a6fb7/fb070/skeleton.png",
        "srcSet": ["/static/c2092a2b1e1e583db16f32cac08a6fb7/d6747/skeleton.png 288w", "/static/c2092a2b1e1e583db16f32cac08a6fb7/09548/skeleton.png 576w", "/static/c2092a2b1e1e583db16f32cac08a6fb7/fb070/skeleton.png 1152w", "/static/c2092a2b1e1e583db16f32cac08a6fb7/fb104/skeleton.png 1728w", "/static/c2092a2b1e1e583db16f32cac08a6fb7/8fefe/skeleton.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Example of a text skeleton state in a data table</Caption>
    <h3 {...{
      "id": "usage"
    }}>{`Usage`}</h3>
    <p>{`Only use skeleton states on container-based components (e.g. tiles and structured lists), data-based components (e.g. data tables and cards), and data-based text. In most cases, action components (e.g. buttons, input fields, checkboxes, toggles) do not need to have a skeleton state.`}</p>
    <p>{`Never represent toast notifications, overflow menus, dropdown items, modals, and loaders with skeleton states. Elements inside a modal may have a skeleton state, but the modal itself should not.`}</p>
    <h2 {...{
      "id": "progressive-loading"
    }}>{`Progressive loading`}</h2>
    <p>{`Progressive loading is when a page loads in batches. The simplest view of the page loads first, followed by progressively more detailed batches until the entire viewport has loaded.`}</p>
    <p>{`The primary batch should show a page’s basic structure (the skeleton state versions of the container-based components), data-based text (the skeleton state version of text) and non-data text. Following batches can include images, content outside of the viewport, interactive (action-based) components, and data-based text.`}</p>
    <p>{`Not all items need a skeleton state and instead can be expressed as negative or white space until they load. For example, a 600 x 600px image can be shown as a 600 x 600px area of white space until the full image loads.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "64.91071428571429%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABOklEQVQ4y62Tz0rDQBCHI75Dbt1EairkjQRPgk/iRWyDtNAlTUCppyKoeBYED158DE1CzSnZ/MUlGSdp3AbtwWAHPmbJ7nz8lrCSLMvStmpXknbqha7rh5qmUVVVh4ihKIpR9QpCyEZ6PYLniDHok+FBn9DB/t6xME8mk9vFYgGUUrAsC2zbFpimuZEZMqUmnJ5TMKbXcDYaPwkhAFzCqlLk868UiPEM2egF4NWFOyH0fX8exzF4nsdd14VvHMcRvU177+3d4SyKYbn8eBDCMAznVbwoijhSdWCM1bTXP7+tzjJezTIWroVpms6bK3PoXvVMluVrYZIktbAoCo5AR2ohhvotLMuSI9CRjcKrRpg30i7kqytn923hDfyzMOGjEAZBcIR/zEIukHFHqhkLHSe1DCNv7S1jSukLrRqx3amEWQwAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "First phase of a dashboard using skeleton states to demonstrate progressive loading",
        "title": "First phase of a dashboard using skeleton states to demonstrate progressive loading",
        "src": "/static/0be2381c19232d52eee6ace5eb36d36a/fb070/prog-loading-1.png",
        "srcSet": ["/static/0be2381c19232d52eee6ace5eb36d36a/d6747/prog-loading-1.png 288w", "/static/0be2381c19232d52eee6ace5eb36d36a/09548/prog-loading-1.png 576w", "/static/0be2381c19232d52eee6ace5eb36d36a/fb070/prog-loading-1.png 1152w", "/static/0be2381c19232d52eee6ace5eb36d36a/fb104/prog-loading-1.png 1728w", "/static/0be2381c19232d52eee6ace5eb36d36a/8fefe/prog-loading-1.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">
  First phase of a dashboard using skeleton states to demonstrate progressive
  loading
    </Caption>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "64.91071428571429%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABW0lEQVQ4y62TUUvCUBTHF30H37wapoHfKOgp6JP0EukKhY01oVgvQlDRcxD00Evfos3ppmyKy02a89+5tzEjjJQ88OOce7jnx7mwSblcTtpUbEvSliiq1ep+uVxWi8VijZALhYLMM4cxtpR8ntE9JldKrLZXYmpld+cwMzebzdt2uw1FUaDrOlqtVoamaUu5IBRVw/GpClm5xkm98ZQJAVziK0LiY1US4uwZUf0FeLVxlwld1zWCIEC3240J2LaNTqcj4GeeLcuCaZpZn5/5vTfTisfBOxzXfciEvu8bk8kEJI77/T48z8NgMBADPFMfvV5PwGvecxwHvu/BdZx4NosxHA4XwjAMjfTJMX7EfD7HHyFmoihaCGk7Ix2OuSBJEgGvV0AIaanfhWuyVHiVCqepdB2m6ZPvvwtv8M+gDR8z4Wg0OqDPRifOicaa8BmdHEdCRitv7F+mLaVPi0GwL3/1LoIAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Second phase of a dashboard using skeleton states to demonstrate progressive loading",
        "title": "Second phase of a dashboard using skeleton states to demonstrate progressive loading",
        "src": "/static/19d6ba661cf688a66b803a6502424bc5/fb070/prog-loading-2.png",
        "srcSet": ["/static/19d6ba661cf688a66b803a6502424bc5/d6747/prog-loading-2.png 288w", "/static/19d6ba661cf688a66b803a6502424bc5/09548/prog-loading-2.png 576w", "/static/19d6ba661cf688a66b803a6502424bc5/fb070/prog-loading-2.png 1152w", "/static/19d6ba661cf688a66b803a6502424bc5/fb104/prog-loading-2.png 1728w", "/static/19d6ba661cf688a66b803a6502424bc5/8fefe/prog-loading-2.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">
  Second phase of a dashboard using skeleton states to demonstrate progressive
  loading
    </Caption>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "64.91071428571429%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABc0lEQVQ4y62TwUrDQBBAI/5Db91Waiv0jwRPgl/iRWxjsZAQU1DqpSCoeBYED178C5OmTVqSFpMmIdt0nFk1LdpD0A68zO6w+5jdJVKhUJA2FduStCUG9Xp9v1qtquVyuYHIpVJJpkwwxtZSLDJcx+RahTX2Kkyt7e4cZuZ2u33b6/VAURTQdR06nU6GpmlruUAUVYPjUxVk5RpOmudPmRAALuEzQiTJS4qcPUPUfAF4teAuEzqO0/V9HwaDAUfAsizo9/sCmlM2TRMMw8jqNKd1b4bJ3/0AbMd5yISe53WDIAAU89FoBK7rwng8FhsoYx2Gw6GAxlSzbRs8zwXHtvl8zmEymSyFYRh26bxJkvDFYgGr8XO+Jjh9oihaCmezmRDisTlKhSRNU5FzIITY1G9hHMecRN+d/Ud4JXrnPJ7jhRC0MCfx15HvV4U3VEQR/DWww8dMOJ1OD/D+dKSFr31O4DgvLdqLjiMhw5Y39i9jl9IH1iGvxokfE1IAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Third phase of a dashboard using skeleton states to demonstrate progressive loading",
        "title": "Third phase of a dashboard using skeleton states to demonstrate progressive loading",
        "src": "/static/1cdfa84bf05905e77979a0835f228533/fb070/prog-loading-3.png",
        "srcSet": ["/static/1cdfa84bf05905e77979a0835f228533/d6747/prog-loading-3.png 288w", "/static/1cdfa84bf05905e77979a0835f228533/09548/prog-loading-3.png 576w", "/static/1cdfa84bf05905e77979a0835f228533/fb070/prog-loading-3.png 1152w", "/static/1cdfa84bf05905e77979a0835f228533/fb104/prog-loading-3.png 1728w", "/static/1cdfa84bf05905e77979a0835f228533/8fefe/prog-loading-3.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">
  Third phase of a dashboard using skeleton states to demonstrate progressive
  loading
    </Caption>
    <h2 {...{
      "id": "loading-components"
    }}>{`Loading components`}</h2>
    <p>{`A loading component is a rotating shape indicating the application is processing.
Use cases include:`}</p>
    <ul>
      <li parentName="ul">{`When data processing takes more than a few seconds but no longer than one minute.`}</li>
      <li parentName="ul">{`When data from a user input is being saved or submitted.`}</li>
    </ul>
    <h3 {...{
      "id": "loading"
    }}>{`Loading`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "/components/loading/usage"
      }}>{`loading component`}</a>{` should be used when the entire page is processing. This is often applied after data is submitted or saved by the user.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "76.33928571428572%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAAB30lEQVQ4y62T24oTQRCGs/NGCQm5yVtIDhfxLkQUvFN2ZeNkTt09x7g5QBZUnGz2YvHCfY6FeQGv1WdYdhIo/+qMQwiKWbHhoyp1+DtVzVQqOK1Wq99sNi9rtVpSrVbfPZKk0Whc1uv1fuXX6XQ6t8PhkHq9HsGnbrd7NO12mwaDAfu3pWAcx6vZbEaTyeQ+SZJcqiAPwjiP4kkeRglsknOcQc0h9/P5nFijFHQc5zqKInJddwOf3ppjGls2rEUj0ybLBpalQc0hG+5ljVLQ87xr3E5CiA0XOY5NSkmgtEUeCJJSAcl1OlbYDfeyxm8Fd82eFmMrpSDBzZ6r/X8W5GIhYCFwas/olTUn1xO0ny/8vwuapsk7wW9JEqKn9gW9HH8gT2BkfdGR/5CTomjY7W+3Q8dT5ELML/b66JH5YXYNUuNhf57r6v0du8MrfnrYBxRtlfK3ge9v41ABuUUcMVXCNRxjCx6K3qt9wc/T6ZSCIND7e9rv0/DZC3ryPAExKT+gMAx0/hBeAX8UrFEK4hYXo3wFd3iM7M3ZWXZ+PspejxSQGcbOkIMVGvb3uONe1igF0zQ9Wa/XxmKxMH58/2YQUUEILowvN5+M9x9TY7VKDdT+iRMttlwuK//rsNZPK/tRA301G9UAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of a large loader in an application",
        "title": "Example of a large loader in an application",
        "src": "/static/8825157578a2ecce65864d80a281c3c0/fb070/larger-loader.png",
        "srcSet": ["/static/8825157578a2ecce65864d80a281c3c0/d6747/larger-loader.png 288w", "/static/8825157578a2ecce65864d80a281c3c0/09548/larger-loader.png 576w", "/static/8825157578a2ecce65864d80a281c3c0/fb070/larger-loader.png 1152w", "/static/8825157578a2ecce65864d80a281c3c0/fb104/larger-loader.png 1728w", "/static/8825157578a2ecce65864d80a281c3c0/8fefe/larger-loader.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Example of a large loader in an application</Caption>
    <h3 {...{
      "id": "inline-loading"
    }}>{`Inline loading`}</h3>
    <p>{`Use the inline loading component when a single component is processing. For example, when a user restarts a stopped application, an inline loading component indicates the system is processing the restart request.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <img {...{
          "src": "/a12da714d1543a1ebf216983682adb8d/inline-loading-animated.gif",
          "alt": "Example of an inline loader"
        }}></img>
        <Caption mdxType="Caption">Example of an inline loader</Caption>
      </Column>
    </Row>
    <h2 {...{
      "id": "load-more"
    }}>{`Load more`}</h2>
    <p>{`A `}<strong parentName="p">{`Load more`}</strong>{` button should be used to extend a list where there only a small fraction of options are displayed. It can also be used in cases where the list of options is populated via a database. Using `}<strong parentName="p">{`Load more`}</strong>{` allows the data to load in progressive batches.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.891304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAB+klEQVQoz4VTyY7aQBA1zZFI+YLco9yjHLKgIRnNB02UHyLKd6BcBggSYLMZEDjGBi94Y5HHhqTyqmGQRpFmWipVu/v51fZaabfbCq/BYFDAXnQ6HaGqqphOp2I8HrMV+D4IAqXX64kHTL/flxhd19lLjslkIr1Sq9WUp9ZoNFKeWxdMvV6XXtO0N81m8wZ21Wq1Koh8haxvhsPhK76P45gze4f760ajUcGeMRVgrlHFywspp84fOPxu2zYBQEidDMOgJEloNpt9PZdcwg8DPkcQPifTNMl1Xd6/Z8x8PhfK4XCQhOv1uprnOW02m3y32/1lT1hhGN7yPYKVfN/X9vs9bbfbI4wxf/gfkH5gTBRFQgFAEnqeVwWAwijK4S+EyOz2BA5LjuNoQRhy5gcmgx2zLHtMeH+fSkLf96r7/Y7Qq/wc/VGG/jooua6jxXGEKpLjOejxvwzjlCThyg1+bDKipZcQSmZi5iOU+U2WPOu+mMwWQ2OZkGGuyPNcsiyb0CpC7z4yBj0F4SaVUzZ+m68Nc/nZMO1PlmWVYey/YAhyys27n6Kr9t521EFF1fplDK8MTZYx9Qp0K6cMf5JOmKRPasy2rWd1CJWcNmmaSW/ZdmG5coqrlSPQ/AcrLhYL+VJ+NevQmS708aTILwjSEshSdLvdImR0Efc/7svqtT0k7MIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a **Load more** button",
            "title": "Example of a **Load more** button",
            "src": "/static/2222cb53459d13f190a04782ab1c1d4e/fb070/load-more.png",
            "srcSet": ["/static/2222cb53459d13f190a04782ab1c1d4e/d6747/load-more.png 288w", "/static/2222cb53459d13f190a04782ab1c1d4e/09548/load-more.png 576w", "/static/2222cb53459d13f190a04782ab1c1d4e/fb070/load-more.png 1152w", "/static/2222cb53459d13f190a04782ab1c1d4e/c3e47/load-more.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of a 'Load more' button</Caption>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      